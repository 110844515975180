import React, { useState, useEffect } from "react";
import "../../assets/admin/css/main.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Logout() {
  const navigate = useNavigate();
  const [error, seterror] = useState("");

  useEffect(() => {
    // Check if the username key exists in session storage
    localStorage.removeItem("adminLogin");
    navigate("/admin/login");
  }, []);

  return <></>;
}
