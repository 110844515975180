import React, { useEffect, useState } from "react";
import Header from "../../../components/Admin/Header";
import Nav from "../../../components/Admin/Nav";
import { Link } from "react-router-dom";
import { Alert } from "antd";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Blog() {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [editdata, seteditdata] = useState("");
  const [getBlogData, setBlogData] = useState([]);
  const url = "https://backend.hssonline.com.au/uploads/blog/";
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Blog Page";
    const tabledata = {
      table: "blog",
      orderby: "id",
    };
    selectdata(tabledata);
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin === null) {
      navigate("/admin/login");
    }
    const lowercasedFilter = searchTerm.toLowerCase();
    const filtered = getBlogData.filter(
      (row) =>
        row.image.toLowerCase().includes(lowercasedFilter) ||
        row.title.toLowerCase().includes(lowercasedFilter) ||
        row.description.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredRecords(filtered);
  }, [searchTerm, getBlogData]);

  const selectdata = (tabledata) => {
    axios
      .post("https://backend.hssonline.com.au/admin/getSelects", tabledata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // Set both the blog data and filtered records initially
        setBlogData(res.data.results);
        setFilteredRecords(res.data.results); // Initialize filtered records
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${url}${row.image}`} // Adjust this if your image URLs are stored differently
          alt="Image" // Provide alt text for accessibility
          style={{ width: "50px", height: "50px" }} // Adjust the size as needed
        />
      ),
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link
            to={`/admin/blog/edit/${row.id}`}
            className="btn-action btn btn-primary"
            title="Edit"
          >
            <FaEdit />
          </Link>

          <button
            className="btn-action btn btn-danger"
            onClick={() => handleDelete(row)}
            title="Delete"
          >
            <FaTrash />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  function handleFilter(event) {
    setSearchTerm(event.target.value); // Update searchTerm state
  }

  const handleDelete = (row) => {
    console.log("Delete row:", row);

    // Ensure the ID from the row is passed into the tabledelete object
    const tabledelete = {
      table: "blog",
      id: row.id, // Set the actual ID from the row
    };
    console.log(tabledelete);
    // Ask for confirmation before deleting
    if (window.confirm("Are you sure? You want to delete this blog")) {
      axios
        .post("https://backend.hssonline.com.au/admin/delete", tabledelete, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("Delete response:", res.data);
          //setShowAlert(true);
          //setShowAlert({ type: "success" });
          // After deletion, fetch the updated data
          const tabledata = {
            table: "blog",
            orderby: "id",
          };
          selectdata(tabledata); // Refresh the blog list after deletion
        })
        .catch((err) => {
          console.error("Error deleting the blog:", err);
        });
    }
  };

  return (
    <div className="container">
      <Header />
      <div className="content">
        <Nav />
        <div className="container mt-4">
          <h3>Blog</h3>
          <div className="text-end">
            <Link to="/admin/blog/add" className="btn btn-primary">
              Add Blog
            </Link>
          </div>
          {showAlert?.type === "success" && (
            <Alert
              type="success"
              description="Blog successfully deleted"
              closable
            />
          )}
          <div className="dataTable-wrapper">
            <div className="text-end mt-4">
              <input
                type="text"
                className="p-1"
                placeholder="Search..."
                onChange={handleFilter}
              />
            </div>
            <DataTable columns={columns} data={filteredRecords} pagination />
          </div>
        </div>
      </div>
    </div>
  );
}
