import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/admin/css/main.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function Header() {
  const location = useLocation();
  return (
    <div>
      <header>
        <div className="sidebar">
          <ul className="nav flex-column mt-5">
            <li className="nav-item">
              <Link
                className={
                  location.pathname.startsWith("/admin/dashboard")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/admin/dashboard"
              >
                <i className="fas fa-tachometer-alt"></i> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname.startsWith("/admin/blog")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/admin/blog"
              >
                <i className="fas fa-blog"></i> Blog
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/admin/logout">
                <i className="fas fa-sign-out-alt"></i> Logout
              </Link>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}
