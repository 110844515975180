import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../../components/Admin/Header";
import Nav from "../../../components/Admin/Nav";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import axios from "axios";
export default function Blogadd() {
  //Param Define
  const [content, setContent] = useState("");
  const [getImage, setImage] = useState("");
  const { id } = useParams();
  const [editdata, seteditdata] = useState("");
  const [gettype, setblogdata] = useState("Blog");
  //Param Define

  const navigate = useNavigate();
  useEffect(() => {
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin === null) {
      navigate("/admin/login");
    }
    document.title = "Blog Add Page";
    if (id != undefined) {
      console.log(id);
      const tabledata = {
        table: "blog",
        id: id, // Set the actual ID from the row
      };
      getdata(tabledata);
    }
  }, []);

  const getdata = (tabledata) => {
    axios
      .post("https://backend.hssonline.com.au/admin/getSelect", tabledata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var desc = res.data.row[0].description;
        setblogdata(res.data.row[0].type);
        setContent(desc);
        seteditdata(res.data.row[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleChange = (value) => {
    setContent(value);
  };
  const handleImage = (event) => {
    const selectedFiles = event.target.files[0];
    setImage(selectedFiles);
  };
  const handleSubmit = (event) => {
    const formData = new FormData();

    formData.append("title", event.target.title.value);
    formData.append("description", content);
    formData.append("image", getImage);
    formData.append("id", event.target.id.value);
    formData.append("type", gettype);
    formData.append("oldimage", event.target.oldimage.value);
    axios
      .post("https://backend.hssonline.com.au/admin/creatblog", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        event.target.reset();
        setTimeout(() => {
          navigate("/admin/blog");
        }, 500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  return (
    <div className="container">
      <Header />
      <div className="content">
        <Nav />
        <div className="container mt-4">
          <h3>Add Blog</h3>
          <div className="formblog p-4">
            <form
              onSubmit={handleSubmit}
              action="javascript:void(0)"
              method="post"
            >
              <div class="mb-3">
                <label for="title" class="form-label">
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="title"
                  defaultValue={editdata.title}
                  required
                  id="title"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
                <label for="image" class="form-label">
                  Upload Image <span className="text-danger">*</span>
                </label>
                <input
                  type="hidden"
                  defaultValue={editdata.image}
                  name="oldimage"
                />
                <input type="hidden" defaultValue={editdata.id} name="id" />
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleImage}
                  class="form-control"
                  name="image"
                  required={!editdata?.image}
                  id="image"
                />
              </div>
              <div class="mb-3">
                <label for="title" class="form-label">
                  Type <span className="text-danger">*</span>
                </label>
                <select
                  class="form-control"
                  onChange={(e) => setblogdata(e.target.value)}
                  name="type"
                  value={gettype}
                >
                  <option value="Blog">Blog</option>
                  <option value="News">News</option>
                </select>
              </div>
              <div class="mb-5">
                <label for="description" class="form-label">
                  Description
                </label>
                <ReactQuill
                  value={content}
                  onChange={handleChange}
                  style={{ height: "200px" }}
                  theme="snow" // You can also use 'bubble' for a different style
                />
                {/* <div className="output mt-4">
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div> */}
              </div>

              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
