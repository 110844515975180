import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Productive from "../components/Productive";
import Blogbanner from "../assets/images/blog_banner.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
export default function BlogSinglepage() {
  const { id } = useParams();
  console.log(id);
  const [gtype, settype] = useState("");
  const [getBlogs, setgetblog] = useState("");
  const [getBlogsall, setgetblogall] = useState([]);

  useEffect(() => {
    let tabledata = {
      table: "blog",
      slug: id,
    };
    axios
      .post("https://backend.hssonline.com.au/admin/getSelectss", tabledata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var desc = res.data.row[0];
        console.log(desc.type);
        setgetblog(desc);
        settype(desc.type);
        if (desc.type !== null) {
          let tabledataa = {
            table: "blog",
            orderby: "id",
            type: desc.type,
          };
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getSelectswhere",
              tabledataa,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var desc = res.data.results;
              console.log(desc);
              setgetblogall(desc);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" }; // Short month name and numeric day
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }
  return (
    <div>
      <Header />
      <section
        className="common_banner d-block"
        style={{ backgroundImage: `url(${Blogbanner})` }}
      >
        <div className="container-xxl h-100">
          <div className="d-flex justify-content-start h-100 align-items-center position-relative z-3">
            <h1 className="text-center w-100">{getBlogs.title}</h1>
          </div>
          <div className="back_btn">
            <Link to="/blog">
              <FontAwesomeIcon icon={faArrowLeftLong} /> Back{" "}
            </Link>
          </div>
        </div>
      </section>
      <section className="d-block py-5 blogsingleinfo">
        <div className="container-xxl">
          <div className="d-flex flex-column gap-3">
            <div
              dangerouslySetInnerHTML={{
                __html: getBlogs.description,
              }}
            />
          </div>
        </div>
      </section>
      <section className="articles pb-5">
        <div className="container-xxl">
          <div className="slider-container pt-5">
            <div className="blog_slider ">
              {getBlogsall.length === 0 ? (
                <p>No results found.</p> // Display message when no results are found
              ) : (
                getBlogsall.map((blog, index) => (
                  <Link
                    to={`/blog-single-page/${blog.slug}`}
                    key={index}
                    target="_blank"
                    className="blog_box gap-3"
                  >
                    <div className="d-block blog_box_img">
                      <img
                        className="w-100 h-100"
                        src={`https://backend.hssonline.com.au/uploads/blog/${blog.image}`} // Replace with blog image URL if available
                        alt="img"
                      />
                    </div>
                    <div className="d-flex flex-column gap-5 p-3">
                      <div className="d-flex flex-column gap-1">
                        <h5>{blog.title}</h5>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              blog.description
                                .split(" ")
                                .slice(0, 20)
                                .join(" ") +
                              (blog.description.split(" ").length > 20
                                ? "..."
                                : ""),
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-1">
                        <h6>{formatDate(blog.date)}</h6>{" "}
                        {/* <Link to={`/blog-single-page/${blog.id}`}>
                              Read More{" "}
                              <FontAwesomeIcon icon={faArrowRightLong} />
                            </Link> */}
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
      <Productive />
      <Footer />
    </div>
  );
}
