import React, { useEffect, useState } from "react";
import Header from "../../components/Admin/Header";
import Nav from "../../components/Admin/Nav";
import axios from "axios";
// import Footer from "../../components/Admin/Footer";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
  const [blogcount, setblogcount] = useState("0");
  const [newscount, setnewscount] = useState("0");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Dashboard Page";
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin === null) {
      navigate("/admin/login");
    }
    let tabledata = {
      user_id: "",
    };
    axios
      .post("https://backend.hssonline.com.au/admin/getcounts", tabledata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setblogcount(res.data.blog);
        setnewscount(res.data.news);
        // Set both the blog data and filtered records initially
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="container">
      <Header />
      <div className="content">
        <Nav />
        <div className="container mt-4">
          <h3>Dashboard</h3>

          {/* Dashboard Cards */}
          <div className="row mt-2">
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card shadow-lg">
                {" "}
                {/* Apply shadow-lg for a larger shadow */}
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold">
                          <b>Blogs</b>
                        </p>
                        <h5 className="font-weight-bolder mt-2">{blogcount}</h5>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                        <i
                          className="ni ni-money-coins text-lg opacity-10"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card shadow-lg">
                {" "}
                {/* Apply shadow-lg for a larger shadow */}
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold">
                          <b>Services</b>
                        </p>
                        <h5 className="font-weight-bolder mt-2">{newscount}</h5>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                        <i
                          className="ni ni-world text-lg opacity-10"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
