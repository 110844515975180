import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Productive from "../components/Productive";
import Blogbanner from "../assets/images/blog_banner.png";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
export default function Blog() {
  const [getBlogs, setgetblog] = useState([]);
  const [getnew, setgetnew] = useState([]);
  useEffect(() => {
    let tabledata = {
      table: "blog",
      orderby: "id",
      type: "Blog",
    };
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getSelectswhere",
        tabledata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var desc = res.data.results;
        console.log(desc);
        setgetblog(desc);
      })
      .catch((err) => {
        console.error(err);
      });
    let tabledataa = {
      table: "blog",
      orderby: "id",
      type: "News",
    };
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getSelectswhere",
        tabledataa,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var desc = res.data.results;
        console.log(desc);
        setgetnew(desc);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" }; // Short month name and numeric day
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }
  return (
    <div>
      <Header />
      <section
        className="common_banner d-block"
        style={{ backgroundImage: `url(${Blogbanner})` }}
      >
        <div className="container-xxl h-100">
          <div className="d-flex justify-content-start h-100 align-items-center position-relative z-3">
            <h1>
              Insights & Innovations: Stay Ahead with Our Hydraulic Expertise!
            </h1>
          </div>
        </div>
      </section>
      <section className="d-block py-5 articles">
        <div className="container-xxl">
          <Tabs defaultActiveKey="home" id="myTab" className="gap-4">
            <Tab eventKey="home" title="Blog">
              <div className="slider-container pt-5">
                <div className="blog_slider ">
                  {getBlogs.length === 0 ? (
                    <p>No results found.</p> // Display message when no results are found
                  ) : (
                    getBlogs.map((blog, index) => (
                      <Link
                        to={`/blog-single-page/${blog.slug}`}
                        key={index}
                        className="blog_box gap-3"
                      >
                        <div className="d-block blog_box_img">
                          <img
                            className="w-100 h-100"
                            src={`https://backend.hssonline.com.au/uploads/blog/${blog.image}`} // Replace with blog image URL if available
                            alt="img"
                          />
                        </div>
                        <div className="d-flex flex-column gap-5 p-3">
                          <div className="d-flex flex-column gap-1">
                            <h5>{blog.title}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  blog.description
                                    .split(" ")
                                    .slice(0, 20)
                                    .join(" ") +
                                  (blog.description.split(" ").length > 20
                                    ? "..."
                                    : ""),
                              }}
                            />
                          </div>
                          <div className="d-flex align-items-center justify-content-between gap-1">
                            <h6>{formatDate(blog.date)}</h6>{" "}
                            {/* <Link to={`/blog-single-page/${blog.id}`}>
                              Read More{" "}
                              <FontAwesomeIcon icon={faArrowRightLong} />
                            </Link> */}
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="News">
              <div className="slider-container pt-5">
                <div className="blog_slider ">
                  {getnew.length === 0 ? (
                    <p>No results found.</p> // Display message when no results are found
                  ) : (
                    getnew.map((blog, index) => (
                      <Link
                        to={`/blog-single-page/${blog.slug}`}
                        key={index}
                        className="blog_box gap-3"
                      >
                        <div className="d-block blog_box_img">
                          <img
                            className="w-100 h-100"
                            src={`https://backend.hssonline.com.au/uploads/blog/${blog.image}`} // Replace with blog image URL if available
                            alt="img"
                          />
                        </div>
                        <div className="d-flex flex-column gap-5 p-3">
                          <div className="d-flex flex-column gap-1">
                            <h5>{blog.title}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  blog.description
                                    .split(" ")
                                    .slice(0, 20)
                                    .join(" ") +
                                  (blog.description.split(" ").length > 20
                                    ? "..."
                                    : ""),
                              }}
                            />
                          </div>
                          <div className="d-flex align-items-center justify-content-between gap-1">
                            <h6>{formatDate(blog.date)}</h6>{" "}
                            {/* <Link to={`/blog-single-page/${blog.id}`}>
                              Read More{" "}
                              <FontAwesomeIcon icon={faArrowRightLong} />
                            </Link> */}
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
      <Productive />
      <Footer />
    </div>
  );
}
