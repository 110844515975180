import React, { useState, useEffect } from "react";
import "../../assets/admin/css/main.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const navigate = useNavigate();
  const [error, seterror] = useState("");

  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin !== null) {
      navigate("/admin/dashboard");
    }
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    let formdata = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    axios
      .post("https://backend.hssonline.com.au/admin/login", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "2") {
          seterror("Invalid detail");
        } else if (res.data.status === "1") {
          seterror("");
          console.log(res.data.row);
          var userData = res.data.row;
          localStorage.setItem("adminLogin", JSON.stringify(userData));
          navigate("/admin/dashboard");
        }
        // Set both the blog data and filtered records initially
      })
      .catch((err) => {
        console.error(err);
      });
    console.log(e.target.email.value);
  };
  return (
    <div class="login-container">
      <form
        onSubmit={handlesubmit}
        class="login-form p-4 border rounded bg-light shadow-sm"
        action="javascript:void(0)"
        method="post"
      >
        <h2 class="text-center mb-4">Login</h2>
        <span className="text-danger">{error}</span>
        <div class="mb-3">
          <label for="email" class="form-label">
            Email address
          </label>
          <input
            type="email"
            class="form-control"
            required
            id="email"
            placeholder="Enter your email"
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">
            Password
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            required
            placeholder="Enter your password"
          />
        </div>

        <button type="submit" class="btn btn-primary w-100">
          Login
        </button>
      </form>
    </div>
  );
}
